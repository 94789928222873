<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">异常订单</div>
      <div class="formDiv" style="width: 70vw">
        <el-form label-position="left" label-width="90px">
          <el-row :gutter="20">
            <el-col :span="2"></el-col>
            <el-col :span="6">
              <el-form-item label="订单号:">
                <el-input v-model="FormData.order_id" placeholder="请输入订单编号" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户手机号:">
                <el-input v-model="FormData.phone" placeholder="请输入用户手机号" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="设备编号:">
                <el-input v-model="FormData.device_id" placeholder="请输入设备编号" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form label-position="left" label-width="90px">
          <el-row :gutter="20">
            <el-col :span="2"></el-col>
            <el-col :span="6">
              <el-form-item label="充电类型:">
                <el-select style="width: 100%" v-model="FormData.type_id" placeholder="请选择充电类型" clearable>
                  <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="6">
              <el-form-item label="运营商:">
                <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%" clearable>
                  <el-option v-for="aa in users" :key="aa.username" :value="aa.username" :label="aa.username" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单时间：" class="time_pick">
                <el-date-picker style="height: 40px; width: 100%" v-model="FormData.orderTime" type="daterange"
                  unlink-panels range-separator=" - " start-placeholder="开始时间" end-placeholder="结束时间" size="small"
                  format="YYYY-MM-DD" value-format="YYYY-MM-DD">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
            <el-col :span="1" style="margin-left:30px"> 
              <Data2Excel api='/order/anomalyorder' :data="searchParam" fileName="异常订单"></Data2Excel>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table ref="multipleTableRef" :data="tableData" :key="tableKey" border v-loading="loading" height="480px"
          style="width: 90%; margin: 20px auto; overflow-y: auto">
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="订单编号" prop="order_id" width="130" />
          <el-table-column label="手机号" prop="phone" width="130" />
          <el-table-column label="设备编号" prop="device_id" width="130" />
          <el-table-column label="充电端口" prop="socket" width="88" />
          <el-table-column label="充电站位置" prop="community" width="130" />
          <el-table-column label="开始时间" prop="start_time" width="170" :formatter="formatDate" />
          <el-table-column label="结束时间" prop="end_time" width="170" :formatter="formatDate" />
          <el-table-column label="支付金额(元)" prop="pay_money" :formatter="rounding" width="120" />
          <el-table-column label="电费(元)" prop="electric_charge" width="110" :formatter="rounding" />
          <el-table-column label="服务费(元)" prop="service_charge" width="110" :formatter="rounding" />
          <el-table-column label="支付方式" prop="pattern_of_payment" />
          <el-table-column label="所属运营商" prop="username" width="130" />
          <el-table-column label="停止原因" prop="stop_charge_status" width="130" />
          <el-table-column label="操作" align="center" fixed="right">
            <template #default="scope">
              <!-- <el-button
                round
                type="warning"
                plain
                size="mini"
                @click="stopCharge(scope.$index, scope.row)"
                >关闭充电</el-button
              > -->
              <el-button round type="warning" plain size="mini"
                @click="todetail(scope.$index, scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-dialogdrag>
          <el-dialog v-model="Dialog.editLevy" title="订单详情" width="60vw" :before-close="closeDialog" class="dialog">
            <el-row class="detail" :gutter="20">
              <el-col :span="2"></el-col>
              <el-col :span="10">
                <span>订单编号：</span><span>{{ Dialog.DialogData.order_id }}</span>
              </el-col>
              <!-- <el-col :span="1"></el-col> -->
              <el-col :span="10">
                <span>手机号：</span><span>{{ Dialog.DialogData.phone }}</span>
              </el-col>
            </el-row>
            <el-row class="detail" :gutter="20">
              <el-col :span="2"></el-col>
              <el-col :span="10">
                <span>设备编号：</span><span>{{ Dialog.DialogData.device_id }}</span>
              </el-col>
              <el-col :span="10">
                <span>站点名称：</span><span>{{ Dialog.DialogData.station_name }}</span>
              </el-col>
            </el-row>
            <el-row class="detail" :gutter="20">
              <el-col :span="2"></el-col>
              <el-col :span="10">
                <span>充电类型：</span><span>{{
                  Dialog.DialogData.type_id == "2" ? "电单车" :  Dialog.DialogData.type_id == "3" ? "直流桩" : '交流桩'
                  }}</span>
              </el-col>
              <el-col :span="10">
                <span>充电端口：</span><span>{{ Dialog.DialogData.socket }}</span>
              </el-col>
            </el-row>
            <el-row class="detail" :gutter="20">
              <el-col :span="2"></el-col>
              <el-col :span="10">
                <span>充电状态：</span><span>{{
                  Dialog.DialogData.status_code == "充电中"
                    ? "正在充电"
                    : "充电异常"
                }}</span>
              </el-col>
              <el-col :span="10">
                <span>开始时间：</span><span>{{ formatDate1(Dialog.DialogData.start_time) }}</span>
              </el-col>
            </el-row>
            <el-row class="detail" :gutter="20">
              <el-col :span="2"></el-col>
              <!-- <el-col :span="10">
              <span>充电电流：</span><span>{{ Dialog.DialogData.electric_current?Dialog.DialogData.electric_current+'A':'' }}</span>
            </el-col> -->
              <el-col :span="10">
                <span>充电电量：</span><span>{{
                  Dialog.DialogData.electric_quantity
                    ? Dialog.DialogData.electric_quantity + "Kw.h"
                    : ""
                }}</span>
              </el-col>
              <el-col :span="10">
              <span>电量：</span><span>{{ Dialog.DialogData.current?Dialog.DialogData.current+'度':'' }}</span>
              </el-col>
              <!-- <el-col :span="10">
                <span>充电电压：</span><span>{{
                  Dialog.DialogData.voltage
                    ? Dialog.DialogData.voltage + "V"
                    : ""
                }}</span>
              </el-col> -->
            </el-row>
            <el-row class="detail" :gutter="20">
              <el-col :span="2"></el-col><el-col :span="10">
                <span>支付方式：</span><span>{{ Dialog.DialogData.pattern_of_payment }}支付</span>
              </el-col>
              <el-col :span="10">
                <span>支付金额：</span><span>{{
                  Dialog.DialogData.pay_money
                    ? Dialog.DialogData.pay_money + "元"
                    : ""
                }}</span>
              </el-col>
              <!-- <el-col :span="10">
              <span>充电电量：</span><span>{{ Dialog.DialogData.electric_quantity?Dialog.DialogData.electric_quantity+'Kw.h':'' }}</span>
            </el-col> -->
              <!-- <el-col :span="10">
                <span>最大功率：</span><span>{{
                  Dialog.DialogData.maximum_power
                    ? Dialog.DialogData.maximum_power + "KW"
                    : ""
                }}</span>
              </el-col> -->
            </el-row>
            <div class="wrapperBottom">
              <div class="">
                <div class="echartszhu">
                  <p class="index_title1 index_title">充电设备电流情况</p>
                  <div ref="line1" style="width: 850px; height: 280px; margin-left: 100px"></div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button class="Btn" @click="closeDialog">关闭</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
        <div class="pagination">
          <el-pagination :current-page="currentPage" :page-size="pageSize" :small="small"
            layout="total, slot, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { onMounted } from "vue";
import { onMounted, reactive, toRefs, ref } from "vue-demi";
import {
  anomaly_order,
  operator_data,
} from "@/request/api";
import * as echarts from "echarts";
import { ElMessage } from "element-plus";
import ProvinceCityCountry from "../../assets/json/address";
import Data2Excel from "@/component/Data2Excel.vue";
import { carList } from '@/common/common.js'

export default {
  name: "UserRecord",
  components:{
    Data2Excel
  },
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime: ["", ""],
      },
      isadmin: false,
      users: [],
      tableKey: 1,
      tableData: [
        {
          user_id: 11,
          contact_way: 13256561235,
          wx_id: "001",
          mailbox: "CD001",
        },
      ],
      carList: carList,
      car: "",

      loading: false,
      provinceData: ProvinceCityCountry.address,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      searchParam: {}
    });
    const echart = echarts;
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };
    const getList = () => {
      let stDate;
      let endDate;
      if (data.FormData.orderTime) {
        stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0] : null;
        endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : null;
      } else if (
        data.FormData.orderTime === null ||
        data.FormData.orderTime === undefined ||
        data.FormData.orderTime === ""
      ) {
        stDate = null;
        endDate = null;
      }
       data.searchParam = {
        phone: data.FormData.phone,
        type_id: data.FormData.type_id,
        device_id: data.FormData.device_id,
        order_id: data.FormData.order_id,
        operator_id: sessionStorage.getItem("userID"),
        username: data.FormData.username,
        start_execute_date: stDate,
        stop_execute_date: endDate,
        page: data.currentPage,
      };

      data.loading = true;
      anomaly_order(data.searchParam).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableKey = Math.random();
            data.tableData = res.data;
            data.total = res.count_data;
            data.tableData.forEach((a) => {
              a.pay_money = Number(a.pay_money)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              a.electric_charge = Number(a.electric_charge)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              a.service_charge = Number(a.service_charge)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            });
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      let minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      let seconds =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); //得到秒数

      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        seconds
      );
    };
    const formatDate1 = (data) => {
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      let minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      let seconds =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); //得到秒数

      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        seconds
      );
    };
    const line1 = ref(null);
    const todetail = (index, row) => {
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
      let aa = [];
      let dd = [];
      let xx = [];
      let yy = [];
      let zz = [];
      let credentials = row.electric_current;
      if (!credentials) {
        credentials = [];
        console.log("为null等", credentials);
      } else {
        JSON.parse(credentials).forEach((a) => {
          aa.push(a);
        });
        if (aa.length > 11) {
          const cc = aa.length / 10;
          for (let i = 0; i < aa.length; i += cc) {
            dd.push(aa.slice(i, i + cc));
          }
          console.log(dd, "ddddd22");

          dd.forEach((y) => {
            xx.push(y[0].time);
            yy.push(y[0].power);
            zz.push(y[0].voltage);
          });
          xx.push(aa[aa.length - 1].time);
          yy.push(aa[aa.length - 1].power);
          zz.push(aa[aa.length - 1].voltage);
          console.log(xx, "yyyyxxxxxxx");
          console.log(yy, "yyyy");
          console.log(zz, "zzzz");
        } else {
          aa.forEach((m) => {
            console.log(m, "mmmmm");
            xx.push(m.time);
            yy.push(m.power);
            zz.push(m.voltage);
          });
        }
      }

      const initEcharts1 = () => {
        const mapwarp = echart.init(line1.value);
        const option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              lineStyle: {
                color: "#48b",
                width: 8,
                type: "solid",
              },
              crossStyle: {
                color: "#1e90ff",
                width: 1,
                type: "dashed",
              },
              shadowStyle: {
                color: "rgba(150,150,150,0.2)",
                width: "auto",
                type: "default",
              },
            },
          },
          legend: {
            // orient: "vertical",
            right: "10%",
            top: "10%",
            textStyle: {
              color: "#333",
              // fontSize: "18",
            },
          },
          grid: {
            top: "30%",
            left: "3%",
            right: "5%",
            bottom: "3%",

            containLabel: true,
          },
          xAxis: {
            name: '时间',
            type: "category",
            boundaryGap: false,
            data: xx,
            axisLabel: {  
            formatter: function(value) {  
              return value.split(' ').join('\n');
              }  
            },
          },
          yAxis: {
            name: '单位',
            type: "value",
            boundaryGap: false,
            boundaryGap: [0, 0.01],
          },
          series: [
            {
              name: "电流（A）",
              type: "line",
              data: yy,
              visible:true,
            }, 
            {
              name: "电压（V）",
              type: "line",
              data: zz,
              visible:true,
            },
          ],
        };
        mapwarp.setOption(option);
        window.addEventListener("resize", function () {
          mapwarp.resize();
          });
        function toggleSeries(index) {  
        var currentOption = myChart.getOption();  

        currentOption.series[index].visible = !currentOption.series[index].visible;  
 
        myChart.setOption(currentOption);  
        }  

        // 切换数据1的显示状态  
        document.getElementById('toggleData1').addEventListener('click', function () {  
          toggleSeries(0); // 0 索引是数据1  
        });  

        // 切换数据2的显示状态  
        document.getElementById('toggleData2').addEventListener('click', function () {  
          toggleSeries(1); // 1 索引是数据2 
        });
      };
      setTimeout(() => {
        initEcharts1();
      }, 250);
    };
    const closeDialog = () => {
      data.Dialog.editLevy = false;
      data.Dialog.DialogData = row;
    };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
      //
    };
    // const getTime = () => {
    //     var aData = new Date();
    //     const nowdate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
    //     data.FormData.orderTime[0] = nowdate

    //     console.log(data.FormData.orderTime,'data.order_timedata.order_time');
    //   }

    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };

    onMounted(() => {
      getList();
      getUsername();
    });
    return {
      ...toRefs(data),
      searchBtn,
      // getTime,
      getList,
      // handleExcel,
      // handlePrint,
      todetail,
      closeDialog,

      handleSizeChange,
      handleCurrentChange,
      formatDate,
      getUsername,
      rounding,
      line1,
      formatDate1
    };
  },
};
</script>

<style>
.formDiv {
  width: 75%;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  height: 100%;
}

.BtnSearch {
  float: left;
  width: 60px;
  margin: 0 4px;
}

.detail {
  line-height: 30px;
  font-size: 16px;
}

.wrapperBottom {
  /* background-color: #fff; */
  width: 97.7%;
  padding: 0 20px 0px 0px;
}

.echarts1 {
  background: #fff;
  width: 650px;
  height: 260px;
  position: relative;
  /* margin-top: 30px; */
}

.echarts1 .index_title {
  position: relative;
  margin-left: 50px;
  /* top: 30px; */
}

.echartszhu {
  /* background: #fff; */

  position: relative;
}

.echartszhu .index_title {
  position: relative;

  /* top: 30px; */
}

.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
}

.index_title1 {
  font-size: 18px;
  color: #333;
  margin-top: 20px;
  /* border-left: 5px solid; */
  padding-left: 20px;
}
</style>